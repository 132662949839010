<template>
    <v-sheet>
        <v-card v-if="loaded">
            <v-card-text>
                <v-form v-model="formData.valid">
                    <v-select
                        outlined dense
                        v-model="formData.pattern"
                        :items="patterns"
                        item-text="text"
                        item-value="value"
                        label="付与日のパターン"
                    ></v-select>
                    <paid-leave-pattern-descriptions :pattern="formData.pattern"/>
                    <div class="mt-3">
                        <v-text-field label="月オフセット（N）" type=number outlined dense
                            v-if="formData.pattern === 1 || formData.pattern === 2"
                            v-model="formData.offsetN"
                            :rules="[v=> (!isNaN(v) && v>=1 && v<=12) || '有効な月を入れてください' ]"
                        >
                            <template v-slot:append>月</template>
                        </v-text-field>
                        <v-text-field label="最大月経過 (M)" type=number outlined dense
                            v-model="formData.offsetM"
                            :rules="[v=> (!isNaN(v) && v>=1 && v<=12) || '有効な月を入れてください' ]"
                        >
                            <template v-slot:append>月</template>
                        </v-text-field>
                        <v-text-field label="指定日 (A)" type=number outlined dense
                            v-if="
                                formData.pattern === 1
                            "
                            v-model="formData.inputA"
                            :rules="[v=> (!isNaN(v) && v>=1 && v<=31) || '1から31までの数字を入れてください' ]"
                        >
                            <template v-slot:append>日</template>
                        </v-text-field>
                        <v-select label="2回目以降のまとめ日" outlined dense
                            v-if="formData.pattern === 4"
                            v-model="formData.specified"
                            :items="[{text:'A',value:1},{text:'B',value:2}]"
                            item-value="value"
                            item-text="text"
                        >
                        </v-select>
                        <div class="d-flex">
                            <div v-if="formData.pattern === 3 || formData.pattern === 4">
                                <div>指定日A(年が無視します)</div>
                                <v-date-picker 
                                    v-model="formData.dateX"
                                    locale="ja"
                                    :rules="[v=> !!v || '指定日A選んでください' ]"
                                ></v-date-picker>
                            </div>
                            <div v-if="formData.pattern === 3 || formData.pattern === 4">
                                <div>指定日B(年が無視します)</div>
                                <v-date-picker 
                                    v-model="formData.dateY"
                                    locale="ja"
                                    :rules="[v=> !!v || '指定日B選んでください' ]"
                                ></v-date-picker>
                            </div>
                        </div>
                    </div>
                    
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn width="48%" color="error" @click="$emit('close')">閉じる</v-btn>
                <v-btn width="48%" @click="setPaidLeavePattern" :disabled="!formData.valid" color="success">確定</v-btn>
            </v-card-actions>
        </v-card>
    </v-sheet>
</template>

<script>
import PaidLeavePatternDescriptions from '../../components/PaidLeave/PaidLeavePatternDescriptions.vue';
export default {
    components: { PaidLeavePatternDescriptions },
    data:()=>({
        loaded:false,
        formData:{
            valid:false,
            pattern:1,
            offsetN:null,
            offsetM:6,
            inputA:null,
            specified:1,
            dateX:null,
            dateY:null
        },
        patterns:[
            {text:"パターンA", value:1},
            {text:"パターンB", value:2},
            {text:"パターンC", value:3},
            {text:"パターンD", value:4},
        ]
    }),
    async mounted(){
        this.formData = (await this.getPaidLeavePattern())[0].value
        this.loaded = true
    },
    methods:{
        async getPaidLeavePattern(){
            let response = await this.$store.dispatch('get',
                {
                    url: '/api/getSettings?codeName=PAIDLEAVEPATTERN',
                    showUI: true,
                })
            if(response && response.result)
                return response.result
        },
        async setPaidLeavePattern() {
            await this.$store.dispatch('post',
                {
                    url: '/api/submitSettings',
                    showUI: true,
                    rawData:{
                        codeName:'PAIDLEAVEPATTERN',
                        value: this.formData,
                    }
                })
            this.$emit("close")
        },
    }
};
</script>

<style>
</style>