<template>
    <div class="disCon">
        <div v-if="pattern===1">
            <h2>パターンA「指定最短日」</h2><br>
            <p>説明：<span style="color:green;">N</span>月（最大<span style="color:green;">M</span>ヶ月, 法定６ヶ月）を経過後最短指定日<span style="color:green;">A</span>にまとめます。</p>
            <p> 例: </p>
            <div style="padding-left:1em;">
                <p> <span style="color:green;">N</span> = 5, <span style="color:green;">M</span> = 6, <span style="color:green;">A</span> = 20日 </p>
                <p style="display: flex;">
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15</td> </tr>
                    </table>
                    <v-icon>mdi-arrow-right</v-icon>
                    <img :src="require('@/assets/images/yukyu1-1.png')" alt="" width="600" style="border-radius:10px;">
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th>名前</th> <th>付与日</th> </tr>
                        <tr> <td>山田</td> <td>2021-07-20</td> </tr>
                        <tr> <td>太郎</td> <td>2021-08-20</td> </tr>
                        <tr> <td>小林</td> <td>2021-09-20</td> </tr>
                    </table>
                </p>
                <p> <span style="color:green;">N</span> = 6, <span style="color:green;">M</span> = 6, <span style="color:green;">A</span> = 20日 </p>
                <p style="display: flex;">
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15</td> </tr>
                    </table>
                    <v-icon>mdi-arrow-right</v-icon>
                    <img :src="require('@/assets/images/yukyu1-2.png')" alt="" width="600" style="border-radius:10px;">
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th>名前</th> <th>付与日</th> </tr>
                        <tr> <td>山田</td> <td>2021-08-20</td> </tr>
                        <tr> <td>太郎</td> <td>2021-08-20</td> </tr>
                        <tr> <td>小林</td> <td>2021-09-20</td> </tr>
                    </table>
                </p>
            </div>
        </div>
        <div v-else-if="pattern===2">
            <h2>パターンB「指定固定月」</h2><br>
            <p>説明：<span style="color:green;">N</span>月（最大<span style="color:green;">M</span>ヶ月, 法定６ヶ月）後の同じ日にします。（ <span style="color:green;">N</span>=0 の場合は入社当日適応）</p>
            <p> 例: </p>
            <div style="padding-left:1em;">
                <p> <span style="color:green;">N</span> = 6, <span style="color:green;">M</span> = 6</p>
                <p style="display: flex;">
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15</td> </tr>
                    </table>
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02 (＋6ヶ月)</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19 (＋6ヶ月)</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15 (＋6ヶ月)</td> </tr>
                    </table>
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th>名前</th> <th>付与日</th> </tr>
                        <tr> <td>山田</td> <td>2021-09-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-09-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-10-15</td> </tr>
                    </table>
                </p>
                <p> <span style="color:green;">N</span> = 0, <span style="color:green;">M</span> = 6</p>
                <p style="display: flex;">
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15</td> </tr>
                    </table>

                    <v-icon>mdi-arrow-right</v-icon>
                    
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02 (＋0ヶ月)</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19 (＋0ヶ月)</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15 (＋0ヶ月)</td> </tr>
                    </table>

                    <v-icon>mdi-arrow-right</v-icon>

                    <table>
                        <tr> <th>名前</th> <th>付与日</th> </tr>
                        <tr> <td>山田</td> <td>2021-03-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-04-15</td> </tr>
                    </table>
                </p>
            </div>
        </div>
        <div v-else-if="pattern===3">
            <h2>パターンC「二極分散」</h2><br>
            <p>説明：二つの日付をそれぞれ<span style="color:green;">A</span>と<span style="color:green;">B</span>を設定し、最大<span style="color:green;">M</span>ヶ月(法定６ヶ月）以内で一番近い日にまとめます。</p>
            <p> 例: </p>
            <div style="padding-left:1em;">
                <p> <span style="color:green;">M</span> = 6, <span style="color:green;">A</span> = 3月1日, <span style="color:green;">B</span> = 9月1日 </p>
                <p style="display: flex;">
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-06-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-12-15</td> </tr>
                    </table>a
                    <v-icon>mdi-arrow-right</v-icon>
                    <img :src="require('@/assets/images/yukyu3-1.png')" alt="" width="600" style="border-radius:10px;">
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th>名前</th> <th>付与日</th> </tr>
                        <tr> <td>山田</td> <td>2021-09-01</td> </tr>
                        <tr> <td>太郎</td> <td>2021-09-01</td> </tr>
                        <tr> <td>小林</td> <td>2022-03-01</td> </tr>
                    </table>
                </p>
                <p>*太郎さんの付与日が2022年3月1日ではない理由は2021-03-19から2022-03-01まで<span style="color:green;">M</span>を超えたからです。</p>
            </div>
        </div>
        <div v-else-if="pattern===4">
            <h2>パターンD「二極分散二種」</h2><br>
            <p>説明：パターンC「二極分散」と同じですが、第二回以後の付与日を指定日<span style="color:green;">A</span>か<span style="color:green;">B</span>にまとめます。</p>
            <p> 例: </p>
            <div style="padding-left:1em;">
                <p> <span style="color:green;">M</span> = 6, <span style="color:green;">A</span> = 3月1日, <span style="color:green;">B</span> = 9月1日, 指定日 = <span style="color:green;">A</span> </p>
                <div style="display: flex;">
                    <table>
                        <tr> <th>名前</th> <th>入社日</th> </tr>
                        <tr> <td>山田</td> <td>2021-06-02</td> </tr>
                        <tr> <td>太郎</td> <td>2021-03-19</td> </tr>
                        <tr> <td>小林</td> <td>2021-12-15</td> </tr>
                    </table>
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th colspan="2">第一回付与</th> </tr>
                        <tr> <td>山田</td> <td>2021-09-01</td> </tr>
                        <tr> <td>太郎</td> <td>2021-09-01</td> </tr>
                        <tr> <td>小林</td> <td>2022-03-01</td> </tr>
                    </table>
                    <v-icon>mdi-arrow-right</v-icon>
                    <table>
                        <tr> <th colspan="2">第二回付与</th> </tr>
                        <tr> <td>山田</td> <td>2022-03-01</td> </tr>
                        <tr> <td>太郎</td> <td>2022-03-01</td> </tr>
                        <tr> <td>小林</td> <td>2023-03-01</td> </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["pattern"],
};
</script>

<style scoped>
.disCon{
    display:grid;
    justify-content: center;
    font-size: 130%;
}
table *{
    border: 1px solid grey;
}
table td, th{
    padding:.5em;
}
</style>