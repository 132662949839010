<template>
    <v-sheet>
        <v-card v-if="loaded">
            <v-card-subtitle>年次有給休暇日数設定</v-card-subtitle>
            <v-card-subtitle class="py-0 my-0">法的変動がない限り、設定を変える必要がありません</v-card-subtitle>
            <v-card-text class="my-3">
                <v-form v-model="formData.valid">
                    <p>P = パターン</p>
                    <div v-for="(pattern, prop) in formData.value" :key="prop">
                        <span style="font-size:150%">{{prop.toUpperCase()}} : </span><span style="font-size:150%">{{labels[prop]}}</span>
                        <table>
                            <tr>
                                <td>継続勤務年数</td>
                                <td v-for="(workedYear, index, i) in pattern" :key="index">
                                    {{
                                        index == "0" ?  "初付与日" :
                                        Object.keys(pattern).length-1 === i ? `+${index}年以上` :
                                        `+${index}年`
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>付与日数</td>
                                <td v-for="(workedYear, index) in pattern" :key="index">
                                    <v-text-field 
                                        v-model="formData.value[prop][index]"
                                        :rules="[v=> (!isNaN(v) && v>0 && v<365) || '']"
                                        dense outlined hide-details
                                        type=number
                                        style="width:100px;"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-btn width="48%" color="error" @click="$emit('close')">閉じる</v-btn>
                <v-btn width="48%" @click="setPaidLeaveDayPattern" :disabled="!formData.valid" color="success">確定</v-btn>
            </v-card-actions>
        </v-card>
    </v-sheet>
</template>

<script>
export default {
    data:()=>({
        loaded:false,
        formData:{
            valid:false,
            codeName:"PAIDLEAVEDAYS",
            value:{
                p1:{ 0:10, 1:11, 2:12, 3:14, 4:16, 5:18, 6:20 },
                p2:{ 0:7, 1:8, 2:9, 3:10, 4:12, 5:13, 6:15 },
                p3:{ 0:5, 1:6, 2:6, 3:8, 4:9, 5:10, 6:11 },
                p4:{ 0:3, 1:4, 2:4, 3:5, 4:6, 5:6, 6:7 },
                p5:{ 0:1, 1:2, 2:2, 3:2, 4:3},
            },
        },
        labels:{
            p1:"週所定労働時間が３０時間以上の労働者、及び週所定労働日数が５日以上(又は１年間の所定労働日数が２１７日以上)の労働者",
            p2:"週所定労働時間が３０時間未満の労働者, 及び週所定労働日数が４日又は１年間の所定労働日数が１６９日から２１６日の労働者",
            p3:"週所定労働時間が３０時間未満の労働者, 及び週所定労働日数が３日又は１年間の所定労働日数が１２１日から１６８日の労働者",
            p4:"週所定労働時間が３０時間未満の労働者, 及び週所定労働日数が２日又は１年間の所定労働日数が７３日から１２０日の労働者",
            p5:"週所定労働時間が３０時間未満の労働者, 及び週所定労働日数が１日又は１年間の所定労働日数が４８日から７２日の労働者",
        }
    }),
    async mounted(){
        this.formData = (await this.getPaidLeavePattern())[0]
        this.loaded = true
    },
    methods:{
        async getPaidLeavePattern(){
            let response = await this.$store.dispatch('get',
                {
                    url: '/api/getSettings?codeName=PAIDLEAVEDAYS',
                    showUI: true,
                })
            if(response && response.result)
                return response.result
        },
        async setPaidLeaveDayPattern() {
            //Fix all input to Number
            for(let pattern in this.formData.value){
                for(let workedYear in pattern){
                    this.formData.value[pattern][workedYear] = Number(this.formData.value[pattern][workedYear])
                }
            }
            await this.$store.dispatch('post',
                {
                    url: '/api/submitSettings',
                    showUI: true,
                    rawData:this.formData
                })
            this.$emit("close")
        },
    }
};
</script>

<style scoped>
table{
    margin: 1em 0;
}
tr td{
    text-align: center;
    font-size: 150%;
    border-radius: 5px;
    border : 1px solid grey;
    padding:1em;
}
</style>