<template>
    <div>
        <div class="d-flex">
            <v-btn @click="patternSettingDisplay = true" color=warning >
                有給付与設定
            </v-btn>
            <v-btn @click="paidLeaveDaySettingDisplay = true" color=warning class="mx-3">
                付与日数設定
            </v-btn>
            <v-btn @click="createAndSaveTeamplate" color=success class="mr-3">
                <v-icon>mdi-microsoft-excel</v-icon>
                有給データ雛形
            </v-btn>
            <v-menu offset-y :close-on-content-click=false :close-on-click=false>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    > 有給データインポート </v-btn>
                </template>
                <v-sheet>
                    <v-file-input
                        outlined dense
                        accept=".xlsx,.xlsm"
                        label="" hide-details
                        placeholder=".xlsx 形式"
                        @change="importData"
                    ></v-file-input>
                    <div class="d-flex justify-center pa-3" style="max-height:50vh; overflow-y:scroll;">
                        <v-list dense v-if="errMsgs.length > 0">
                            <v-list-item v-for="(err, index) in errMsgs" :key="index">
                                {{err}}
                            </v-list-item>
                        </v-list>
                        <v-btn @click="upload" color=success v-if="formData && errMsgs.length === 0">アップロード</v-btn>
                    </div>
                </v-sheet>
            </v-menu> 
        </div>
        <div v-if="checkedData">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th colspan="5">
                                <v-btn color=success @click="confirmImportData">これで問題ありません。更新行ってください。</v-btn>
                            </th>
                        </tr>
                        <tr>
                            <th class="text-left">
                                名前
                            </th>
                            <th class="text-left">
                                入社日
                            </th>
                            <th class="text-left">
                                初付与日
                            </th>
                            <th class="text-left">
                                付与最小値（参考用）
                            </th>
                            <th class="text-left">
                                入力値
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in checkedData" :key="user.userId">
                            <td>{{ user.name }}</td>
                            <td>{{ user.joinedDate }}</td>
                            <td>{{ user.firstIssueDate }}</td>
                            <td>
                                <div v-if="(!user.paidLeaveInfo || !user.paidLeaveInfo.info)">付与なし</div>
                                <div v-else>
                                   <v-chip v-for="(labels, prop) in user.paidLeaveInfo.info" :key="prop" label>
                                       {{prop.split("expIn")[1]}}年有効: {{labels}}日
                                    </v-chip>
                                </div>
                            </td>
                            <td>
                                <div>
                                   <v-chip v-for="(labels, prop) in user.userInputPaidLeaveInfo" :key="prop" label>
                                       {{prop.split("expIn")[1]}}年有効: {{labels}}日
                                    </v-chip>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div v-else>
            <v-simple-table v-if="userPaidLeaveData">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-left">
                                名前
                            </th>
                            <th class="text-left">
                                入社日
                            </th>
                            <th class="text-left">
                                初付与日
                            </th>
                            <th class="text-left">
                                有給休暇日数(今年有効)
                            </th>
                            <th class="text-left">
                                有給休暇日数(来年有効)
                            </th>
                            <th class="text-left">
                                有給状況({{new Date().getFullYear()}}年分)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in userPaidLeaveData" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{user.userId.name}}</td>
                            <td>{{user.userId.joined}}</td>
                            <td>{{user.firstIssueDate || "未設定"}}</td>
                            <template v-if="user.paidLeaveInfo">
                                <td v-for="(paidLeave, prop) in user.paidLeaveInfo" :key="prop">
                                    <div v-if="!paidLeave.days">なし</div>
                                    <v-chip label v-else>
                                        {{paidLeave.available}}日/{{paidLeave.days}}日
                                    </v-chip>
                                </td>
                            </template>
                            <template v-else>
                                <td>未設定</td>
                                <td>未設定</td>
                            </template>
                            <td>
                                {{user.currentPaidleave.length}}日 
                                <v-btn color=primary small>詳細</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <div :style="'display:none;'" id="paidLeaveTable"></div>
        <!---Dialogs--->
        <v-dialog persistent v-model="patternSettingDisplay">
            <paid-leave-settings-panel @close="patternSettingDisplay = false" />
        </v-dialog>
        <v-dialog persistent v-model="paidLeaveDaySettingDisplay">
            <paid-leave-day-setting-panel @close="paidLeaveDaySettingDisplay = false" />
        </v-dialog>
    </div>
</template>

<script>
import paidLeaveSettingsPanel from './paidLeavePatternSettingsPanel.vue'
import paidLeaveDaySettingPanel from './paidLeaveDaySettingPanel.vue'
import Spreadsheet from "x-data-spreadsheet";
import XLSX from 'xlsx'
export default {
    data:()=>({
        patternSettingDisplay:false,
        paidLeaveDaySettingDisplay:false,
        s:null,
        errMsgs:[],
        formData:null,
        checkedData:null,
        userPaidLeaveData:null
    }),
    components:{
        paidLeaveSettingsPanel,
        paidLeaveDaySettingPanel
    },
    async mounted(){
        this.userPaidLeaveData = await this.getUserData()
        console.log(this.userPaidLeaveData)
    },
    methods:{
        async upload(){
            let response = await this.$store.dispatch('post', {url:"/api/checkPaidLeaveData", showUI:true, rawData:{parsedData:this.formData}})
            if(response && response.result){
                this.checkedData = response.result
            }     
        },
        importData(e){
            let v = this
            let reader = new FileReader();
            v.errMsgs = []
            if(!e){
                v.formData = null
                return
            }
            reader.readAsBinaryString(e);
            reader.onload = function(e) { 
                let  data = e.target.result;
                let  workbook = XLSX.read(data, {type: 'binary'}); 
                let jsonResult = XLSX.utils.sheet_to_json(workbook.Sheets.有給, {header:1})
                v.checkJson(jsonResult)
                v.formData = jsonResult
            };
        },
        checkJson(data){
            let errorMsgs = this.errMsgs
            for(let i = 0 ; i<data.length ; i++){
                let record = data[i]
                if(i === 0) continue
                if(record.length != 6){
                    errorMsgs.push(`第${i}行目, 項目数が足りません`)
                    continue
                }
                if(record[3] <= 0 || record[3] > 5){
                    errorMsgs.push(`第${i}行目, 無効な付与パターンです`)
                    continue
                }
                if(isNaN(record[4])){
                    errorMsgs.push(`第${i}行目, 「所持有給日数(今年有効)」に有効な数字を入れてください`)
                    continue
                }
                if(isNaN(record[5])){
                    errorMsgs.push(`第${i}行目, 「所持有給日数(来年有効)」に有効な数字を入れてください`)
                    continue
                }
            }
        },
        async createAndSaveTeamplate(){
            let userList = this.userPaidLeaveData
            this.reconstructDataStructure(userList)
            this.save()
        },
        save(){
            var new_wb = this.xtos(this.s.getData());
            XLSX.writeFile(new_wb, "有給雛形.xlsx");
        },
        async getUserData(){
            const response = await this.$store.dispatch('get', {
                url:'/api/paidLeaveUserList',
                showUI:true
            })
            if(response && response.result)
                return response.result
        },
        async confirmImportData(){
            const response = await this.$store.dispatch('get', {
                url:'/api/confirmImportData',
                showUI:true
            })
            if(response && response.result) {
                this.userPaidLeaveData = await this.getUserData()
                this.checkedData = null
            }
            
        },
        reconstructDataStructure(userPaidLeaveData){
            let dataStructure = {
                name:"有給",
                rows:{
                    0:{
                        cells:{
                            0:{text:"ID"},
                            1:{text:"名前"},
                            2:{text:"入社日"},
                            3:{text:"付与日数パターン(1~5を入力してください)"},
                            4:{text:"所持有給日数(今年有効)"},
                            5:{text:"所持有給日数(来年有効)"},
                        }
                    }
                }
            }
            userPaidLeaveData.forEach((user, index) => {
                dataStructure["rows"][index+1] = {}
                dataStructure["rows"][index+1]["cells"] = {}
                dataStructure["rows"][index+1]["cells"]["0"] = {text:user.userId._id}
                dataStructure["rows"][index+1]["cells"]["1"] = {text:user.userId.name}
                dataStructure["rows"][index+1]["cells"]["2"] = {text:user.userId.joined}
            });
            this.s = new Spreadsheet("#paidLeaveTable")
            .loadData(dataStructure) // load data
        },
        stox(wb) {
            var out = [];
            wb.SheetNames.forEach(function(name) {
                var o = {name:name, rows:{}};
                var ws = wb.Sheets[name];
                var aoa = XLSX.utils.sheet_to_json(ws, {raw: false, header:1});
                aoa.forEach(function(r, i) {
                var cells = {};
                r.forEach(function(c, j) { cells[j] = ({ text: c }); });
                o.rows[i] = { cells: cells };
                })
                out.push(o);
            });
            return out;
        },
        xtos(sdata) {
            var out = XLSX.utils.book_new();
            sdata.forEach(function(xws) {
                var aoa = [[]];
                var rowobj = xws.rows;
                for(var ri = 0; ri < rowobj.len; ++ri) {
                var row = rowobj[ri];
                if(!row) continue;
                aoa[ri] = [];
                Object.keys(row.cells).forEach(function(k) {
                    var idx = +k;
                    if(isNaN(idx)) return;
                    aoa[ri][idx] = row.cells[k].text;
                });
                }
                var ws = XLSX.utils.aoa_to_sheet(aoa);
                XLSX.utils.book_append_sheet(out, ws, xws.name);
            });
            return out;
        }
    }
};
</script>
